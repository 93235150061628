import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
const routes: Routes = [
  // 'prod/auth/landing'
  // 'prod/auth/login'
  // prod/auth/register
  // prod/dashboard
  {
    path: '',
    redirectTo: 'prod/auth/landing',
    pathMatch: 'full'
  },
  {
    path: 'prod',
    children: [
      {
        path: 'auth',
        children: [
          {
            path: 'landing',
            loadChildren: () => import('./pages/prod/auth/landing/landing.module').then(m => m.LandingPageModule)
          },
          {
            path: 'login',
            loadChildren: () => import('./pages/prod/auth/login/login.module').then(m => m.LoginPageModule)
          },
        ]
      },
      {
        path: 'dashboard',
        children: [
          // Free pages
          {
            path: '',
            loadChildren: () => import('./pages/prod/dashboard/dashboard.module').then(m => m.DashboardPageModule)
          },
          {
            path: 'liveclass',
            loadChildren: () => import('./pages/prod/liveclass/liveclass.module').then( m => m.LiveclassPageModule)
          },
          
          {
            path: 'notice',
            loadChildren: () => import('./pages/prod/notice/notice.module').then( m => m.NoticePageModule)
          },
          {
            path: 'fees-info',
            children:[
              {
                path: '',
                loadChildren: () => import('./pages/prod/fees-info/fees-info.module').then( m => m.FeesInfoPageModule)
              },
              {
                path: 'fees',
                loadChildren: () => import('./pages/prod/fees/fees.module').then( m => m.FeesPageModule)
              },
              {
                path: 'generate-bill',
                loadChildren: () => import('./pages/prod/generate-bill/generate-bill.module').then( m => m.GenerateBillPageModule)
              },
              {
                path: 'payment-history',
                loadChildren: () => import('./pages/prod/payment-history/payment-history.module').then( m => m.PaymentHistoryPageModule)
              },
            ]
          },
          
          {
            path: 'ediary',
            loadChildren: () => import('./pages/prod/ediary/ediary.module').then( m => m.EdiaryPageModule)
          },
          {
            path: 'ediary-detail',
            loadChildren: () => import('./pages/prod/ediary-detail/ediary-detail.module').then( m => m.EdiaryDetailPageModule)
          },
          {
            path: 'study-material',
            children: [
              {
                path:'',
                loadChildren: () => import('./pages/prod/study-material/study-material.module').then( m => m.StudyMaterialPageModule)
              },
              {
                path: 'details',
                loadChildren: () => import('./pages/prod/studymaterial/details/details.module').then( m => m.DetailsPageModule)
              },            
            ]
          },
          {
            path: 'homework',
            loadChildren: () => import('./pages/prod/homework/homework.module').then( m => m.HomeworkPageModule)
          },
          {
            path: 'doubt-clear',
            children:[
              {
                path:'',
                loadChildren: () => import('./pages/prod/doubt-clear/doubt-clear.module').then( m => m.DoubtClearPageModule)
              },
              {
                path: 'chat',
                loadChildren: () => import('./pages/prod/doubtclear/chat/chat.module').then( m => m.ChatPageModule)
              },
            
            ]
          },
          { 
            path:'online-exam',
            children:[
              {
                path: 'schedule',
                loadChildren: () => import('./pages/prod/online-exam/schedule/schedule.module').then( m => m.SchedulePageModule)
              },
              {
                path: 'exam',
                children:[
                  {
                    path:'',
                    loadChildren: () => import('./pages/prod/online-exam/exam/exam.module').then( m => m.ExamPageModule)
                  },
                  {
                    'path':'start',
                    loadChildren: ()=> import('./pages/prod/modals/exam-view/exam-view.module').then(m=> m.ExamViewPageModule)
                  }
                ]
              },
              {
                path: 'result',
                loadChildren: () => import('./pages/prod/online-exam/result/result.module').then( m => m.ResultPageModule)
              },
              {
                path: 'answersheet',
                loadChildren: () => import('./pages/prod/online-exam/answersheet/answersheet.module').then( m => m.AnswersheetPageModule)
              }
            ]
          },     
          {
            path: 'exam-history',
            loadChildren: () => import('./pages/prod/sidemenu/exam-history/exam-history.module').then( m => m.ExamHistoryPageModule)
          },
        ]
      }
    ]
  },
  {
    path: 'notice-dtl',
    loadChildren: () => import('./pages/prod/notice-dtl/notice-dtl.module').then( m => m.NoticeDtlPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
