import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private deviceUUID: any;
  constructor(private storage: Storage) {
    //
  }
  //
  async setUserDeviceUUID(uuid): Promise<any> {
    return await this.storage.set('user_uuid', uuid);
  }
  async getUserDeviceUUID(): Promise<any> {
    return await this.storage.get('user_uuid');
  }
  //
  setUserPhoneNum(data: any): Promise<any> {
    return this.storage.set('user_phone', data);
  }
  async getUserPhoneNum(): Promise<any> {
    return await this.storage.get('user_phone');
  }
  //
  async setUserData(data: any): Promise<any> {
    // console.log(data)
    return await this.storage.set('user_data', data);
  }

  setUserCredential(data: any): Promise<any> {
    // console.log(data)
    return this.storage.set('user_credential', data);
  }

  async getUserLoginData(): Promise<any> {
    return await this.storage.get('user_data');
  }

  async removeUserLoginData(): Promise<any> {
    const data = await this.storage.remove('user_data');
    console.log(data);
    return data;
  }

  async setUserExamData(key: string, data: any): Promise<any> {
    // console.log(data)
    return await this.storage.set(key, data);
  }
  async getUserExamData(key: string): Promise<any> {
    console.log(key);
    return await this.storage.get(key);
  }
}
