import { Component, OnInit } from '@angular/core';

import { ActionSheetController, MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { UserDataService } from './services/user-data.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Network } from '@ionic-native/network/ngx';
import { UtilService } from './services/util.service';


// some code 
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Dashboard',
      url: 'prod/dashboard',
      icon: 'apps'

    },
    {
      title: 'Help',
      url: 'prod/dashboard',
      icon: 'help-circle'

    },
    {
      title: 'Exam History',
      url: 'prod/dashboard/exam-history',
      icon: 'file-tray'

    },
    {
      title: 'Logout',
      url: 'prod/auth/login',
      icon: 'log-out'
    }
  ];
  networkAlert:any;
  disconnectSubscription:any;
  connectSubscription:any;

  constructor(
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private actionCtrl: ActionSheetController,
    private navCtrl: NavController,
    private user: UserDataService,
    private iab: InAppBrowser,
    private network: Network,
    private util: UtilService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    //
    this.platform.ready().then(() => {
      this.platform.backButton.subscribeWithPriority(1, () => { // to disable hardware back button on whole app
      });
  
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#154a69');
      this.splashScreen.hide();
      this.disconnectSubscription = this.network.onDisconnect().subscribe(async () => {
        console.log('network was disconnected :-(');
        this.networkAlert = await this.util.createAlert('No Internet',     false, 'Please Check you internet Connection and try again',{
        text: '',
        role: '',
        cssClass: 'secondary',
        handler: async () => {}
        });
        this.networkAlert.present();
        });
        this.connectSubscription = this.network.onConnect().subscribe(() => {
        console.log('network connected!');
        if(this.networkAlert) {
        this.networkAlert.dismiss();
       // this.checkUser();
        }
        });
    });
  }


  async ngOnInit() {
    console.log('ngOnInit');
    this.menuCtrl.enable(false);

    let connectSubscription = this.network.onConnect().subscribe(() => {
      console.log('network connected!');
      // We just got a connection but we need to wait briefly
       // before we determine the connection type. Might need to wait.
      // prior to doing any api requests as well.
      setTimeout(() => {
        console.log(this.network.downlinkMax);
        if (this.network.type === 'wifi') {
          console.log('we got a wifi connection, woohoo!');
        }
      }, 3000);
    });
    
    

  }

  async menuNavigation(url) {
    console.log('url is ', url);
    if (url == 'prod/auth/login') {
      this.menuCtrl.enable(false);
      await this.user.removeUserLoginData();
      this.router.navigate([url], { replaceUrl: true });
    } else {
      this.router.navigate([url]);
    }
  }
  goToAddress(url)
  {
    this.iab.create(url);
  }
  
}
