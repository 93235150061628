import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController, Platform, NavController, AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';


export enum SYS {
  iOS = 'ios',
  Android = 'android',
  Desktop = 'desktop'
}


@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private genericLoading: any;
  public System = SYS;
  private modalCloseObservable = new Subject<string>();
  public modalClose = this.modalCloseObservable.asObservable();

  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private platform: Platform,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private router: Router
  ) {
    //
  }
  public async presentLoading(message: string = null) {
    if (this.genericLoading == null) {
      this.genericLoading = await this.loadingCtrl.create({ message });
      return await this.genericLoading.present();
    }
  }

  public async dismissLoading() {
    setTimeout(() => {
      if (this.genericLoading != null) {
        this.loadingCtrl.dismiss();
        this.genericLoading = null;
      }
    }, 1000);
  }

  public async showSimpleToast(msg: string) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2500
    });
    toast.present();
  }
  public async showSimpleToastHttp() {
    const toast = await this.toastCtrl.create({
      message: 'Please check your internet connection and try again.',
      duration: 2500
    });
    toast.present();
  }
  public getPlatform(): string {
    let platform: string;
    if (this.platform.is(SYS.Desktop)) {
      platform = SYS.Desktop;
    } else if (this.platform.is(SYS.iOS)) {
      platform = SYS.iOS;
    } else if (this.platform.is(SYS.Android)) {
      platform = SYS.Android;
    }
    return platform;
  }
  public getYYYY_MM_DD() {
    let prettyDate: string;
    //
    const dateObj = new Date();
    // adjust 0 before single digit date
    const date = String(('0' + dateObj.getDate()).slice(-2));
    // current month
    const month = String(('0' + (dateObj.getMonth() + 1)).slice(-2));
    // current year
    const year = String(dateObj.getFullYear());
    //
    prettyDate = year + '-' + month + '-' + date;
    // prints date in YYYY-MM-DD format
    // console.log(year + '-' + month + '-' + date);
    return prettyDate;
  }
  async createAlert(header, backdropDismiss, message, buttonOptions1, buttonOptions2?): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create({
    header,
    backdropDismiss,
    message,
    buttons: !buttonOptions2 ? [buttonOptions1] : [buttonOptions1,           buttonOptions2]
    });
    return alert;
    }
    async presentAlertConfirm(header,msg,url) {
      const alert = await this.alertCtrl.create({
        header: header,
        message: msg,
        buttons: [
          {
            text: 'Okay',
            handler: (e) => {
              this.router.navigate([url]);
            }
          }
        ]
      });
    
      await alert.present();
    }
}
