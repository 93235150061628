import { enableProdMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
//
import { IonicStorageModule } from '@ionic/storage';
import { Media } from '@ionic-native/media/ngx';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { BehaviorSubject, Observable } from 'rxjs';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { DecimalPipe } from '@angular/common';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FTP } from '@ionic-native/ftp/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Sim } from '@ionic-native/sim/ngx';
import { Device } from '@ionic-native/device/ngx';
import { SafePipe } from './safe.pipe';
import { CommonModule } from '@angular/common';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { IonicSelectableModule } from 'ionic-selectable';

enableProdMode();

// Some code

@NgModule({
    declarations: [AppComponent, SafePipe],
    imports: [
        HttpClientModule,
        FormsModule,
        BrowserModule,
        CommonModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: 'ourva-edn-db',
            driverOrder: ['indexeddb', 'sqlite', 'websql']
        }),
        AppRoutingModule,
        IonicSelectableModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        DatePicker,
        Media,
        InAppBrowser,
        DecimalPipe,
        Camera,
        File,
        FileTransfer,
        DocumentViewer,
        FileChooser,
        FilePath,
        FTP,
        Network,
        Sim,
        Device,
        AndroidPermissions,
        BackgroundMode,
        WebIntent,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
